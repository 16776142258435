import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web-orbit/app/Progess.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web-orbit/components/Contact/ContactForm.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/components/Footer/footer.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web-orbit/components/Home/Particles/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/components/Navbar/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/components/Reuse/lottie/AnimationComponent.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/components/Reuse/ThemeButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web-orbit/components/Technologies/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
